






















import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
import PaymentActions from '@/views/components/mixins/PaymentActions'
import Dialog from '@/calendesk/models/Dialog'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import BookingDialogModel from '@/calendesk/models/BookingDialogModel'
import Service from '@/calendesk/models/DTO/Response/Service'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
export default mixins(DraftElement, PaymentActions).extend({
  name: 'DefaultButton',
  props: {
    button: {
      type: Object,
      required: true
    },
    additionalClass: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getButtonWidth (): number | null {
      if (this.$vuetify.breakpoint.width / 2 <= this.button.width) {
        return null
      }

      return this.button.width
    }
  },
  methods: {
    handleButtonAction () {
      if (this.button.pageUrl) {
        if (this.button.openInNewTab) {
          window.open(this.button.pageUrl, '_blank', 'noopener')
        } else {
          location.href = this.button.pageUrl
        }
      } else if (this.button.pageId) {
        this.redirectToPageUuid(this.button.pageId)
      } else if (this.button.sspId) {
        this.handleSimpleProductStoreAction()
      } else if (this.button.serviceId) {
        this.handleBookingAction()
      } else if (this.button.subscriptionId) {
        this.redirectToSubscription(this.button.subscriptionId)
      }
    },
    handleSimpleProductStoreAction () {
      const configurationData = {
        configuration: {
          wb_show_billing_data__checkbox__: typeof this.button.showBillingData === 'undefined' ? true : this.button.showBillingData,
          wb_require_billing_data__checkbox__: !!this.button.requireBillingData,
          wb_show_name_surname__checkbox__: typeof this.button.showNameAndSurnameFields === 'undefined' ? true : this.button.showNameAndSurnameFields,
          wb_show_phone_number__checkbox__: typeof this.button.showPhoneNumberField === 'undefined' ? true : this.button.showPhoneNumberField,
          wb_require_phone_number__checkbox__: typeof this.button.phoneNumberRequired === 'undefined' ? true : this.button.phoneNumberRequired
        }
      }

      this.openDialog(
        new Dialog(
          true,
          DialogTypes.SIMPLE_STORE_PRODUCT_CONFIRMATION_DIALOG,
          this.$trans('simple_store_product_confirmation_title'),
          DialogSize.MIDDLE,
          false,
          'center',
          {
            productId: this.button.sspId,
            configurationData,
            button: this.button
          },
          true
        ))
    },
    handleBookingAction () {
      const service = this.services.find((service: Service) => service.id === this.button.serviceId)
      if (service) {
        let serviceType = null
        if (this.button.serviceTypeId) {
          serviceType = service.types.find((type: ServiceType) => type.id === this.button.serviceTypeId)
        }

        const bookingDialogModel = new BookingDialogModel(
          service,
          null,
          serviceType,
          null,
          this.$moment().format(DateTimeFormats.FULL),
          null,
          null)

        let data = {}

        if (this.button.bookingFormData) {
          data = {
            configuration: {
              wb_booking_dynamic_form__dynamic_form__: this.button.bookingFormData,
              wb_show_billing_data__checkbox__: typeof this.button.showBillingData === 'undefined' ? true : this.button.showBillingData,
              wb_require_billing_data__checkbox__: !!this.button.requireBillingData,
              wb_show_name_surname__checkbox__: typeof this.button.showNameAndSurnameFields === 'undefined' ? true : this.button.showNameAndSurnameFields,
              wb_show_phone_number__checkbox__: typeof this.button.showPhoneNumberField === 'undefined' ? true : this.button.showPhoneNumberField,
              wb_require_phone_number__checkbox__: typeof this.button.phoneNumberRequired === 'undefined' ? true : this.button.phoneNumberRequired,
              wb_initial_location_select__checkbox__: !!this.button.selectInitialLocation
            }
          }
        }

        this.openDialog(new Dialog(
          true,
          DialogTypes.CREATE_BOOKING,
          null,
          DialogSize.LARGE,
          false,
          'center',
          {
            dialogData: bookingDialogModel,
            configurationData: data
          })
        )
      }
    }
  }
})
